import logo from './warriorslogo.png';
import errorLogo from './errorIcon.png';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { Toast } from 'primereact/toast';
import axios from 'axios'

import './App.css';
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';

import { ToggleButton } from 'primereact/togglebutton';

import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

const { GoogleSpreadsheet } = require('google-spreadsheet');
const doc = new GoogleSpreadsheet('1hxcSUWkKDHKkTQKqtTynuhVHnjKx8UIyCAgBcnHMkQI');
const evalDoc = new GoogleSpreadsheet('14UAJYVPx2Qt5UtyFRzowwA-6tggmYsZABtDc_qJzWx8');
const attendenceOptions = ['No', 'Yes'];

// Data Grid Columns
const columns = [
  { field: 'Ranking', header: 'Ranking\n(1 - player count (1-15) etc.)' }, 
  { field: 'FirstName', header: 'First' },
  { field: 'LastName', header: 'Last' },
  { field: 'Position', header: 'Position' },
  { field: 'SkatingAbility', header: 'Skating\nAbility or \nGoalie: Mobility' },
  { field: 'Passing', header: 'Passing or \nGoalie: Glove Hand' },
  { field: 'Shooting', header: 'Shooting or \nGoalie: Blocker side' },
  { field: 'PuckControl_StickHandling', header: 'Puck Control /\nStick Handling or \nGoalie: Rebound Ctrl' },
  { field: 'ScoringAbility', header: 'Scoring\nAbility or \nGoalie: Compete Level' },
  { field: 'PlayMakingAbility', header: 'Playmaking\nAbility or \nGoalie: Composure' },
  { field: 'PositionalPlay', header: 'Positional\nPlay' },
  { field: 'SupportsPuckCarrier', header: 'Supports\nPuck Carrier' },
  { field: 'TeamPlay', header: 'Team\nPlay' },
  { field: 'Intensity_Agressiveness', header: 'Intensity/\nAgressiveness' },
  { field: 'Coachability_Attitude', header: 'Coachability/\nAttitude' },
  { field: 'Regular_Attendence', header: 'Regularly Attends Practices/Games' },
  { field: 'Notes', header: 'Coach Notes' }
];

let localData =
{
  isSubmitted: false,
  team: '',
  teamData: []
}

// MAIN FUNCTION APP
function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showEvalutatorName, setShowEvaluatorName] = useState(true);
  const [evaluatorName, setEvaluatorName] = useState('');
  const toast = useRef(null);
  const [players, setPlayers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);
  const [teamPlayerEvalList, setTeamPlayerEvalList] = useState([]);
  const [submittedTeams, setSubmittedTeams] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [ip, setIP] = useState('');
  const [evaluators, setEvaluators] = useState([]);
  const [activeEvaluator, setActiveEvaluator] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // evaluationTitle - Title of Google Sheet
  const [evaluationTitle, setEvaluationTitle] = useState('');
  const [evaluatorFromParam, setEvaluatorFromParam] = useState(false);

  const queryParams = new URLSearchParams(window.location.search)
  const evaluator = queryParams.get("evaluator");

  // Get IP Address from geolocation-db.com
  const getIPAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  }

  // Get All Teams / Player Info - Google Sheets
  const getTeamsAndPlayerInfo = async () => {
    setIsLoading(true);
    setLoadingMessage('Validating Evaluator Data');

    await doc.useServiceAccountAuth({
      client_email: 'playerevalserviceaccount@player-evaluations-370214.iam.gserviceaccount.com',
      private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDkcMhyYBqTLVDn\npzVMB0Fg3ghkmwpXzPlDJ3QUjdk2nTAAgzoAi48LKeo9vy3Ni+C0vilNh8JuN42z\nUI55PcSyzZF4Ft/Z1qrB/BKr4K/Z9s9sk6gIMqFmxD09Q5gE2cgtvp4Oxj+oYYaF\n1T6yTYytPXah3RoELMPx/dmLCaAFbmqIisVcUcrrr7wpYoxKJjtN8YVWs5MpUQR7\nC7aRZqaK8WvXwglT1QXQrBU7wExiqwXm7FkeuGEP/EqCoSKsH+ZUle5vBTiASDsj\nvKUG27MJAPpC/0tMFQ5ZNpqAbRPyhYqbvsmqYtCmM3Q2+GrkjGOpHHukLe2dkAaw\nmpjbGU8fAgMBAAECggEATvUck0vcY5p6KFeG+GxZkJ2t0Pg1rvtN/IQafzA4L8RM\n3yB8X8vc+ULXj9gQgMckN9SyefW+olHZTG0/kXBkGFktOpDNdoHYK4xw96+FmJTS\nRPkUTyEQ4HBbql0+og6UPhtwjjK/tfdfg8qdDQv437gq1O+TGnZOk8nl80S0ZquI\nQPkuFll0VOjCOuZYk8ZimJkoA6VLDrcxuaVS8w8Lo+EFfIyYzvH6DvTmPOGJXYMx\n5EPXU74qD3PXKjWm71HwBC6Ex9ODTrtOg+6HsymLbBEoBb+7ajtiIwizKnbAy2QB\nK8XIcmmpDA1o+vEKp0jiNdhb2fIGZZs/nZZwR9PR4QKBgQD0MmqAjoS5Nuz0mku7\n8iKT++b+cOXgebXkMoPPoD4TrRyrFjwFcwx6we4jR/3IEKC41OpxVAFoMYX30cRh\na2MgT2oLlGNuXRMbHgENM4eDV6po8WZKb4YXq403pD4kUH2etwx6TYEGP2U6JWRg\ni/QCV+/1aVPkomuYEe3Vj51z5QKBgQDve2eRnR6im+KoxxetvMogB/839ZxZFo8k\nQH63+A1rdZRGL3xH09K0AOAnAOrQo3MMqIXdlH8IRBAseAeNQVxETRCpU2sZuC0Q\nevB86LJ0y0eaggQJkGaAE5GJs4WPAQtZyn0w2MReKOQj0s9VEUQN+NutZa9+HPuY\n99ev69POswKBgQDH/ZY9rPrD2VjcxpTp2tc4zSAUGBAb9HHj7v8GhtzwLmGBOJd1\nexCoZLUMTgRo2j1WwoEyyTLRy2VFXZVwl4s6Re+6hg+crjtchQklb6M3DQ/yHGnC\n4m02a3gfLrL5+LW4y387lsfpfvf/U+pOK7BuTuil21oqsF5fz6cxtQmlhQKBgQDG\nZCEEjHkYlLNMxsJi94cqyfUwUpRXaUzj51oS5beYUiuniScYCe7/l8oaBbSBayag\nUeu0CJTEMD6F2FnJN2BMZNtsquKKx/ztLiuUDuHnx49l/FeD5NBFQfDLdqEqOt9A\nX3MeyDTFHHMDCl0gR9FtEcBLAXZEr5Xuv0M7Rc6N6QKBgCnEOrZ5I8hsZst5WL/P\nInHuAuD66JvH6l1dkY2fSAY16+bDeNmZh3lXnUnD9ftU0SMgPLqUlRyV6Nag99N9\n4BZX8s66cEXsdqWshImyKANhA86OYZQn1XaA12Gsp3x+OHRhRIHA5pxBk4ykXn+5\nVYa9oy3VvWpEgHH5SXVGER9h\n-----END PRIVATE KEY-----',
    });

    await doc.loadInfo(); // loads document properties and worksheets

    setLoadingMessage('Loaded Evaluation Data');

    const allPlayerSheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    const evaluatorSheet = doc.sheetsByIndex[1];
    const tempRows = await allPlayerSheet.getRows();

    if (localData.teamData.length == 0) {
      setPlayers(tempRows);
    }

    let tempEvaluators = await evaluatorSheet.getRows();
    setEvaluators(tempEvaluators);

    // console.log(evaluator);
  };

  // Get Evaluation Google Sheet
  const getEvaluationGoogleSheet = async () => {
    setIsLoading(true);
    setLoadingMessage('Validating Evaluator Data');

    await evalDoc.useServiceAccountAuth({
      client_email: 'playerevalserviceaccount@player-evaluations-370214.iam.gserviceaccount.com',
      private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDkcMhyYBqTLVDn\npzVMB0Fg3ghkmwpXzPlDJ3QUjdk2nTAAgzoAi48LKeo9vy3Ni+C0vilNh8JuN42z\nUI55PcSyzZF4Ft/Z1qrB/BKr4K/Z9s9sk6gIMqFmxD09Q5gE2cgtvp4Oxj+oYYaF\n1T6yTYytPXah3RoELMPx/dmLCaAFbmqIisVcUcrrr7wpYoxKJjtN8YVWs5MpUQR7\nC7aRZqaK8WvXwglT1QXQrBU7wExiqwXm7FkeuGEP/EqCoSKsH+ZUle5vBTiASDsj\nvKUG27MJAPpC/0tMFQ5ZNpqAbRPyhYqbvsmqYtCmM3Q2+GrkjGOpHHukLe2dkAaw\nmpjbGU8fAgMBAAECggEATvUck0vcY5p6KFeG+GxZkJ2t0Pg1rvtN/IQafzA4L8RM\n3yB8X8vc+ULXj9gQgMckN9SyefW+olHZTG0/kXBkGFktOpDNdoHYK4xw96+FmJTS\nRPkUTyEQ4HBbql0+og6UPhtwjjK/tfdfg8qdDQv437gq1O+TGnZOk8nl80S0ZquI\nQPkuFll0VOjCOuZYk8ZimJkoA6VLDrcxuaVS8w8Lo+EFfIyYzvH6DvTmPOGJXYMx\n5EPXU74qD3PXKjWm71HwBC6Ex9ODTrtOg+6HsymLbBEoBb+7ajtiIwizKnbAy2QB\nK8XIcmmpDA1o+vEKp0jiNdhb2fIGZZs/nZZwR9PR4QKBgQD0MmqAjoS5Nuz0mku7\n8iKT++b+cOXgebXkMoPPoD4TrRyrFjwFcwx6we4jR/3IEKC41OpxVAFoMYX30cRh\na2MgT2oLlGNuXRMbHgENM4eDV6po8WZKb4YXq403pD4kUH2etwx6TYEGP2U6JWRg\ni/QCV+/1aVPkomuYEe3Vj51z5QKBgQDve2eRnR6im+KoxxetvMogB/839ZxZFo8k\nQH63+A1rdZRGL3xH09K0AOAnAOrQo3MMqIXdlH8IRBAseAeNQVxETRCpU2sZuC0Q\nevB86LJ0y0eaggQJkGaAE5GJs4WPAQtZyn0w2MReKOQj0s9VEUQN+NutZa9+HPuY\n99ev69POswKBgQDH/ZY9rPrD2VjcxpTp2tc4zSAUGBAb9HHj7v8GhtzwLmGBOJd1\nexCoZLUMTgRo2j1WwoEyyTLRy2VFXZVwl4s6Re+6hg+crjtchQklb6M3DQ/yHGnC\n4m02a3gfLrL5+LW4y387lsfpfvf/U+pOK7BuTuil21oqsF5fz6cxtQmlhQKBgQDG\nZCEEjHkYlLNMxsJi94cqyfUwUpRXaUzj51oS5beYUiuniScYCe7/l8oaBbSBayag\nUeu0CJTEMD6F2FnJN2BMZNtsquKKx/ztLiuUDuHnx49l/FeD5NBFQfDLdqEqOt9A\nX3MeyDTFHHMDCl0gR9FtEcBLAXZEr5Xuv0M7Rc6N6QKBgCnEOrZ5I8hsZst5WL/P\nInHuAuD66JvH6l1dkY2fSAY16+bDeNmZh3lXnUnD9ftU0SMgPLqUlRyV6Nag99N9\n4BZX8s66cEXsdqWshImyKANhA86OYZQn1XaA12Gsp3x+OHRhRIHA5pxBk4ykXn+5\nVYa9oy3VvWpEgHH5SXVGER9h\n-----END PRIVATE KEY-----',
    });

    await evalDoc.loadInfo();

    setLoadingMessage('Loaded Evaluation Data');

    setEvaluationTitle(evalDoc.title);
  };

  // Get base data from Google Sheets
  useEffect(() => {

    getIPAddress();

    if (!evaluator) {
      setShowError(true);
      setErrorMessage('there appears to be required information missing to process this request.');
      setEvaluatorFromParam(false);
      return;
    }

    // get submitted teams
    if (localStorage.getItem("submittedTeams")) {
      setSubmittedTeams(JSON.parse(localStorage.getItem("submittedTeams")));
    }

    setEvaluatorFromParam(true);

    setLoadingMessage('Loading Evaluator Data');

    getTeamsAndPlayerInfo();
    getEvaluationGoogleSheet();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  // IsLoading changing
  useEffect(() => {

  }, [isLoading]);

  useEffect(() => {
    // console.log("Show Error: " + showError);
  }, [showError]);

  // evaluator list set
  useEffect(() => {
    // check if evaluator is legit
    // setIsLoading(false);
    if (evaluators.length === 0)
      return;

    var matchingEvaluators = evaluators.filter(e => {
      return e.EvaluatorCode === evaluator;
    });

    if (matchingEvaluators.length == 0) {
      // no match
      setShowError(true);
      setErrorMessage('we can\'t find an evaluator with that matches that code. ');
    } else {
      setActiveEvaluator(matchingEvaluators[0]);
      //console.log(matchingEvaluators[0]);
      setEvaluatorName(matchingEvaluators[0].Name);
      setSelectedTeam(matchingEvaluators[0].Team);
      localData.team = matchingEvaluators[0].Team;

      setShowEvaluatorName(false);
      setShowError(false);
      // showSuccessToast('Data Loaded', 'Your evaluator information has been loaded from local storage');
    }

  }, [evaluators]);

  useEffect(() => {

  }, [evaluatorName]);

  // Set Teams ... when Player load completes
  useEffect(() => {

    if (players.length > 0) {
      // console.log("PLAYERS ARE LOADED");
    }

  }, [players]);

  // selectedTeam changed
  useEffect(() => {
    if (selectedTeam === '' || selectedTeam === undefined)
      return;

    if (selectedTeam !== '' || selectedTeam !== undefined) {

      // 👇️ filter with 1 condition
      var filtered = players.filter(player => {
        return player.Team === selectedTeam;
      });

      localStorage.setItem("selectedTeamName", JSON.stringify(selectedTeam));
      setLoadingMessage('Loading Team Data');

      // does data exist for this evaluator ... locally
      if (localStorage.getItem(evaluator)) {
        // has value
        // console.log("HAS LOCAL DATA FOR EVALUATOR: " + evaluator);
        localData = JSON.parse(localStorage.getItem(evaluator));
        if (localData.team == '') {
          localData.team = selectedTeam;
          localStorage.setItem(evaluator, JSON.stringify(localData));
        }

        // console.log(localData.teamData);
        if (localData.teamData.length == 0) {
          // console.log("Team Data is empty")
        }
      } else {
        // Create Local Storage object for this evaluator
        localData.team = selectedTeam;
        localStorage.setItem(evaluator, JSON.stringify(localData));
      }

      if (localData.teamData.length > 0) {
        // console.log("Loading Team Data from STORAGE");
        // console.log(localData);
        setSelectedTeamPlayers(localData.teamData);
      } else {
        // console.log(localData);
        // console.log("Loading Team Data from Sheet");
        setSelectedTeamPlayers(filtered);
      }
    } else {
      // empty
      // localStorage.removeItem("selectedTeamName");
      // console.log('setting empty');
      // setSelectedTeamPlayers([]);
    }
  }, [selectedTeam]);

  // onsubmittedteams change
  useEffect(() => {
    if (submittedTeams.length === 0)
      return;


    console.log("On Submitted Teams Changing");
    // console.log(submittedTeams);
    if (submittedTeams.includes(selectedTeam)) {
      // disable
      // console.log("INCLUDES");
      setIsButtonDisabled(true)
    } else {
      // enable
      setIsButtonDisabled(false);
    }
  }, [submittedTeams]);

  // Generate teamPlayerEvalList
  useEffect(() => {
    var tempList = [];

    if (selectedTeamPlayers.length === 0)
      return;

    if (localData.teamData.length == 0) {
      // console.log("LOADING GOOGLE SHEET")

      selectedTeamPlayers.forEach((el) => {
        tempList.push({
          PlayerId: el.PlayerId,
          FirstName: el.FirstName,
          LastName: el.LastName,
          Team: el.Team,
          Birthdate: el.Birthdate ?? '',
          Position: el.Position ?? '',
          SkatingAbility: el.SkatingAbility ?? 1,
          Passing: el.Passing ?? 1,
          Shooting: el.Shooting ?? 1,
          PuckControl_StickHandling: el.PuckControl_StickHandling ?? 1,
          ScoringAbility: el.ScoringAbility ?? 1,
          PlayMakingAbility: el.PlayMakingAbility ?? 1,
          PositionalPlay: el.PositionalPlay ?? 1,
          SupportsPuckCarrier: el.SupportsPuckCarrier ?? 1,
          TeamPlay: el.TeamPlay ?? 1,
          Intensity_Agressiveness: el.Intensity_Agressiveness ?? 1,
          Coachability_Attitude: el.Coachability_Attitude ?? 1,
          Regular_Attendence: el.Regular_Attendence ?? true,
          Notes: el.Notes ?? '',
          Ranking: el.Ranking ?? 1
        });
      });
      console.log(tempList);
    } else {
      // load from storage
      // console.log("LOADING FROM STORAGE")
      localData.teamData.forEach((el) => {
        tempList.push({
          PlayerId: el.PlayerId,
          FirstName: el.FirstName,
          LastName: el.LastName,
          Team: el.Team,
          Birthdate: el.Birthdate ?? '',
          Position: el.Position ?? '',
          SkatingAbility: el.SkatingAbility ?? 1,
          Passing: el.Passing ?? 1,
          Shooting: el.Shooting ?? 1,
          PuckControl_StickHandling: el.PuckControl_StickHandling ?? 1,
          ScoringAbility: el.ScoringAbility ?? 1,
          PlayMakingAbility: el.PlayMakingAbility ?? 1,
          PositionalPlay: el.PositionalPlay ?? 1,
          SupportsPuckCarrier: el.SupportsPuckCarrier ?? 1,
          TeamPlay: el.TeamPlay ?? 1,
          Intensity_Agressiveness: el.Intensity_Agressiveness ?? 1,
          Coachability_Attitude: el.Coachability_Attitude ?? 1,
          Regular_Attendence: el.Regular_Attendence ?? true,
          Notes: el.Notes ?? '',
          Ranking: el.Ranking ?? 1
        });
      });
      // console.log(tempList);
    }

    setTeamPlayerEvalList(tempList);

  }, [selectedTeamPlayers]);

  // check selectedTeam every render
  useEffect(() => {
    if (selectedTeam === '' || selectedTeam === undefined) {
      // enable submit
      setIsButtonDisabled(true);
    }
    else {
      if (submittedTeams.includes(selectedTeam + "-" + evaluator)) {
        // disable
        setIsButtonDisabled(true)
      } else {
        // enable
        setIsButtonDisabled(false);
      }
    }
  });

  // changes to localData object
  // useEffect(() => {
  //   console.log(localData);
  //   localStorage.setItem("localData", JSON.stringify(localData));
  // }, [localData]);

  // bind table to eval list 
  useEffect(() => {
    // bind table to eval list
    if (teamPlayerEvalList.length === 0)
      return;

    setIsLoading(false);

    if (localData.teamData.length == 0) {
      // fill with values
      // console.log("localData.teamData is empty");
      localData.teamData = teamPlayerEvalList;
      // save ... 
      localStorage.setItem(evaluator, JSON.stringify(localData));
    }

  }, [teamPlayerEvalList]);

  function handleSelectChange(val) {
    // perform query for table ... 
    setSelectedTeam(val);
  }

  // Ensure a positive integer is entered
  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  // Cell Editing Complete - fire
  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case 'SkatingAbility':
      case 'Ranking':
      case 'Passing':
      case 'Shooting':
      case 'PuckControl_StickHandling':
      case 'ScoringAbility':
      case 'PlayMakingAbility':
      case 'PositionalPlay':
      case 'SupportsPuckCarrier':
      case 'TeamPlay':
      case 'Intensity_Agressiveness':
      case 'Coachability_Attitude':
        if (isPositiveInteger(newValue))
          rowData[field] = newValue;
        else
          event.preventDefault();
        break;
      case 'Notes':
        rowData[field] = newValue;
        break;
      case "Regular_Attendence":
        rowData[field] = newValue;
        break;

      default:
        if (newValue.trim().length > 0)
          rowData[field] = newValue;
        else
          event.preventDefault();
        break;
    }

    // save to local storage
    // fill with values
    localData.teamData = teamPlayerEvalList;
    // save ... 
    localStorage.setItem(evaluator, JSON.stringify(localData));
  }

  // Submit form
  async function handleSubmitClick() {
    await addEvalRow();
  }

  // Create Google Sheet Row and Submit
  async function addEvalRow() {
    const evalSheet = evalDoc.sheetsByIndex[0];

    var tempArray = [];
    teamPlayerEvalList.forEach(async (el) => {

      tempArray.push({
        PlayerId: el.PlayerId,
        FirstName: el.FirstName,
        LastName: el.LastName,
        Team: el.Team,
        Birthdate: el.Birthdate,
        Position: el.Position,
        SkatingAbility: el.SkatingAbility,
        Passing: el.Passing,
        Shooting: el.Shooting,
        PuckControl_StickHandling: el.PuckControl_StickHandling,
        ScoringAbility: el.ScoringAbility,
        PlayMakingAbility: el.PlayMakingAbility,
        PositionalPlay: el.PositionalPlay,
        SupportsPuckCarrier: el.SupportsPuckCarrier,
        TeamPlay: el.TeamPlay,
        Intensity_Agressiveness: el.Intensity_Agressiveness,
        Coachability_Attitude: el.Coachability_Attitude,
        Regular_Attendence: (el.Regular_Attendence == true) ? 'Yes' : 'No',
        Notes: el.Notes,
        Ranking: el.Ranking,
        Evaluator: evaluatorName,
        EvaluatorIP: ip,
        EvaluationDate: new Date()
      });

    });

    await evalSheet.addRows(tempArray);
    submittedTeams.push(selectedTeam + "-" + evaluator);
    setSubmittedTeams(submittedTeams);
    localStorage.setItem("submittedTeams", JSON.stringify(submittedTeams));
    setIsButtonDisabled(true);
    setSelectedTeam('');
    console.log("Completed");
    showSuccess();
  }

  const cellEditor = (options) => {
    if (options.field === 'FirstName' || options.field === 'LastName')
      return readonlyEditor(options);
    else if (options.field === 'Position')
      return textEditor(options);
    else if (options.field === 'Notes') {
      return textAreaEditor(options);
    }
    else if (options.field === 'Regular_Attendence') {
      return checkboxEditor(options);
    }
    else if (options.field === 'Ranking') {
      return rankingEditor(options);
    }
    else
      return numberEditor(options);
  }

  const readonlyEditor = (options) => {
    return <InputText type="text" value={options.value} readOnly />;
  }

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const textAreaEditor = (options) => {
    return <InputTextarea value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const numberEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} min={1} max={5} locale="en-US" />
  }

  const rankingEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} min={1} max={100} locale="en-US" />
  }

  const checkboxEditor = (options) => {
    return <ToggleButton checked={(options.value)} onChange={(e) => options.editorCallback(e.value)} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />
  }

  const regAttendenceBodyTemplate = (rowData) => {
    if (rowData.Regular_Attendence) {
      return <Tag className="mr-2" style={{ cursor: 'pointer' }} severity="success" value="Yes"></Tag>
    }
    else {
      return <Tag className="mr-2" style={{ cursor: 'pointer' }} severity="danger" value="No"></Tag>
    }
  }

  const notesBodyTemplate = (rowData) => {

    if (rowData.Notes && rowData.Notes.length > 0) {
      return <i className="custom-target-icon pi pi-book" style={{ cursor: 'pointer' }} title={rowData.Notes}></i>
    }
    else {
      return <i className="custom-target-icon pi pi-pencil" style={{ cursor: 'pointer' }} title="Click to add notes"></i>
    }
  }

  // TOAST MESSAGES
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Submitted Successfully', detail: 'Your evaluation has been submitted successfully', life: 3000 });
  }

  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  }

  const showSuccessToast = (summaryString, detailString) => {
    toast.current.show({ severity: 'success', summary: summaryString, detail: detailString, life: 3000 });
  }

  const showEvaluatorNameSave = () => {
    toast.current.show({ severity: 'success', summary: 'Info Saved', detail: 'Your evaluator information has saved!', life: 3000 });
  }

  const showEvaluatorNameError = () => {
    toast.current.show({ severity: 'error', summary: 'Please enter value', detail: 'You need to supply a name!', life: 3000 });
  }

  // Save Username Function
  const saveUser = () => {
    if (evaluatorName === '' || evaluatorName === undefined) {
      showEvaluatorNameError();
    }
    else {
      // save user to local storage
      localStorage.setItem("localEvaluatorName", JSON.stringify(evaluatorName));
      setShowEvaluatorName(false);
      showEvaluatorNameSave();
    }
  };

  // Edit Username
  const editEvaluatorName = () => {
    if (!evaluatorFromParam) {
      setShowEvaluatorName(true);
    }
  };

  // Confirmation Dialog for Submitting Data
  const confirmSubmit = () => {
    confirmDialog({
      message: 'Are you sure you are ready to submit? This is final ...',
      header: 'Finished?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => handleSubmitClick(),
      reject: () => reject
    });
  }

  // Save Username Footer
  const footer = (
    <span>
      <Button label="Save" icon="pi pi-check" style={{ marginRight: '5px' }} onClick={saveUser} />
      {/* <Button label="Cancel" icon="pi pi-times" className="p-button-secondary ml-2" /> */}
    </span>
  );

  return (

    <div className="App">
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="logo" height="80px" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">Link</a>
              </li> */}
            </ul>
            <form className="d-flex">
              <Button hidden={showEvalutatorName || showError} className='p-button-danger p-button-text' style={{ fontWeight: 'bold' }} onClick={editEvaluatorName}>
                Welcome {evaluatorName} &nbsp;&nbsp; <i hidden={evaluatorFromParam} className="pi pi-user-edit"></i>
              </Button>
            </form>
          </div>
        </div>
      </nav>
      <header className="App-header">
        {evaluationTitle} - {selectedTeam}
        <span className='App-Description'>
          To edit scores, simply click on the value, change to desired score (1 - 5) and exit cell.
        </span>

        <div className='Subnav' hidden={showEvalutatorName || showError || isLoading} style={{ padding: '10px' }}>
          <Button id='btnSubmit' className='p-button-danger p-button-rounded' disabled={isButtonDisabled} label="Submit Evaluation" onClick={confirmSubmit} />
        </div>
      </header>
      <ConfirmDialog />
      <div hidden={!isLoading} style={{ textAlign: 'center' }}>
        <ProgressSpinner /><br />
        <span>{loadingMessage}</span>
      </div>
      <Toast ref={toast} />

      <div className="card" hidden={showError}>
        <DataTable value={teamPlayerEvalList} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">
          {
            columns.map(({ field, header }) => {
              return <Column key={field} field={field} header={header} style={{ width: '8%' }} body={(field === 'Notes' && notesBodyTemplate) || (field === 'Regular_Attendence' && regAttendenceBodyTemplate)}
                editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
            })
          }
        </DataTable>
      </div>

      <div className="alert alert-danger" role="alert" style={{ width: '60%', minWidth: '400px', margin: 'auto', padding: '10px' }} hidden={!showError}>
        <img src={errorLogo} width="50px" style={{ float: 'left', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>We're sorry</span> - {errorMessage}  Please contact johnstownwarriorstreasurer@gmail.com with questions or support items.
      </div>
    </div>
  );
}

export default App;